.container_proyectos{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container_proyecto{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 90vh;
}

@media screen and (max-width: 1024px){
  .container_proyecto{
    display: flex;
    width: 100%;
    height: 250vh;
    flex-wrap: wrap;
  }
    
}

@media screen and (max-width: 768px){
  .container_proyecto{
    display: flex;
    
    height: 150vh;
    flex-wrap: wrap;
  }
    
}
  
@media screen and (max-width: 550px){
  .container_proyecto{
    display: flex;  
    height: 240vh;
    flex-wrap: wrap;
  }
    
}