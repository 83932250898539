.container_skills{
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.container_skill{
  padding: 30px 30px 30px 30px;
}
.container_skill p{
  margin: 0;
  text-align: center;
  font-family: 'Bebas Neue', cursive;
  color: #e4e6e7;
  font-size: 30px;
}
.container_img{
  transition: transform 0.6s, -webkit-transform 0.6s;
}
.container_img:hover{
  transform: scale(1.1);
  /* -webkit-transform: scale(1.1); */
}

@media screen and (max-width: 768px){
  .container_skill{
    height: 100%;
    
  }
  .container_img {
    width: 100px;
    height: 100px;
  }

  .container_img img{
    width: 100%;
    height: 100%;
  }

  .container_skill p{
    font-size: 25px;
  }
}

@media screen and (max-width: 550px){
  .container_img {
    width: 80px;
    height: 80px;
  }

  .container_img img{
    width: 100%;
    height: 100%;
  }

  .container_skill p{
    font-size: 20px;
  }
  .container_skill{
    padding: 10px 10px 10px 10px;
  }
}

@media screen and (max-width: 375px){
  .container_skill{
    
    height: 70px;
    padding: 10px 10px 10px 10px;
  }
  .container_img {
    width: 70px;
    height: 70px;
  }


  .container_skill p{
    font-size: 15px;
  }
}
