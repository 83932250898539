.container_navbar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0,0,0,0.1);
  font-family: 'Bebas Neue', cursive;
  font-size: 25px;
  font-weight: 600;
  margin: 15px 0 15px 0;
  position: fixed;
  height: 60px;
  width: 100%;
  z-index: 2;
}

.changeColor{
  background-color: #1d0e11;
}

.inicio{
  padding-left: 20px;
}
.link{
  cursor: pointer;
  margin-right: 35px ;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  transition: background-size .3s;
}
.link:hover, .link:focus {
  background-size: 100% 2px;
}


.linkChange{
  color: #fff !important;
}



.a{
  display: flex !important;
  align-items: center;
  cursor: pointer;
  margin-right: 25px;
  text-decoration: none;
  color: #1d0e11;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  transition: background-size .3s;
}
.a:hover, .a:focus {
  background-size: 100% 2px;
}
.icon{
  margin: 2px 0 0 9px
}
.rutas{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
}
.nav_toggle{
  display: none;
  cursor: pointer;
  line-height: 59px;
  font-size: 20px;
  background-color: transparent;
  border: none;
  padding-top: 5px;
}

.ButtonTranslation{
  display: flex;
  margin-right: 10px;
}

@media screen and (max-width: 1024px){
  .container_navbar{
    justify-content: flex-start;
    margin: 0;
    width: 100%;
  }
}

@media screen and (max-width: 768px){
  .link{
    margin-right: 15px
  }

  .inicio{
    padding-left: 15px;
  }
}

@media screen and (max-width: 525px){
  .container_navbar{
    justify-content: space-around;
    margin: 0;
    width: 100%;
  }
  
  .rutas{
    flex-direction: column;
    position: fixed;
    background-color: #fff;
    top: 59px;
    width: 100%;
    height: calc(100% - 60px);
    overflow-y: auto;
    left: 100%;
    transition: left 0.3s;
    justify-content: space-evenly;
  }

  .link{
    margin: 0;
  }
  
  .inicio Link{
    padding: 0;
    margin: 0;
    
  }

  .inicio{
    padding: 0;
  }

  .nav_toggle {
    display: block;
  }
  .nav_menu_visible {
    left: 0;
  }

  .ButtonTranslation{
    margin: 0;
  }
}



  
