
.container_proyectoCard{
  height: 680px;
  width: 450px;
  align-items: center;
  background-color: #e4e6e7;
  border: 1px solid #cd4118bf;
  border-radius: 5px;
  box-shadow: 0 20px 25px -10px rgba(0, 0, 0, 0.5);
  font-family: 'DM Serif Display', serif;
}
.texto{
  text-align: center;
  /* color: rgb(240, 18, 18); */
}
.texto_container{
  flex-direction: column;
  padding: 20px;
  height: 15em;
  
}
.container_icons{
  display: flex;
  justify-content: space-evenly;
  padding-top: 10px;
}

.a {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 4px;
  color: #cd4118bf;
  position: relative;
  overflow: hidden;
  z-index: 1;
  -webkit-transition: color 150ms ease-in-out;
  transition: color 150ms ease-in-out;
  border: 1px solid #cd4118bf;
  height: 40px;
  width: 120px;
  text-align: center;
  font-size: 20px;
  padding: 5px;
  text-decoration: none;
  
}
.a:after {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  height: 100%;
  background: #cd4118bf;
  z-index: -1;
  -webkit-transition: width 150ms ease-in-out;
  transition: width 150ms ease-in-out;
  
}
.a:hover {
  color: #fff;
  text-decoration:none;
}
.a:hover:after {
  width: 110%;
  /* text-decoration:none; */
}

.video{
  width:450px;
  height:315px
}

@media screen and (max-width: 1024px) {
  .container_proyectoCard{
    width: 400px;
    height: auto;
  }

  .video{
    width: 100%;
    height: 300px;
  }

  .texto{
    font-size: 18px;
  }

  .a {
    width: 110px;
    font-size: 19px;
    height: 30px;
  }
  .container_icons{
    padding-bottom: 15px;
  }
}

@media screen and (max-width: 768px) {
  .container_proyectoCard{
    width: 300px;
    height: auto;
  }

  .video{
    height: 200px;
  }

  .texto_container{
    padding: 10px
  }

  .texto{
    font-size: 15px;
  }

  .container_icons{
    padding-bottom: 10px;
  }
  
}

  @media screen and (max-width: 320px){
    .container_proyectoCard{
      width: 250px;
      height: auto;
    }

    .video{
      width: 100%;
      height: 200px;
    }

    .texto{
      font-size: 14px;
    }

    .a {
      width: 100px;
      font-size: 18px;
      height: 25px;
    }
    .container_icons{
      padding-bottom: 10px;
    }
  }
