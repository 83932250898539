.container_titulo{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Bebas Neue', cursive;
  font-size: 1.5rem;
  background-color: #e4e6e7;
  border-radius: 10px 10px 10px 10px;
  width: 400px;
  color: #1d0e11;
  border: 1px solid #cd4118bf;
  margin-bottom: 20px;
  box-shadow: -7px 10px 11px 0px rgba(205,65,24,0.75);
  -webkit-box-shadow: -7px 10px 11px 0px rgba(205,65,24,0.75);
  -moz-box-shadow: -7px 10px 11px 0px rgba(205,65,24,0.75);
}
.container_img{
  height: 300px;
  transition: transform 1.6s, -webkit-transform 0.6s;
}
.container_img:hover{
  transform: scale(2.1);
}


@media screen and (max-width: 768px){


  .container_img:hover{
    transform: scale(1.5);
  }
}


@media screen and (max-width: 550px){
  .container_titulo{
    width: 300px;
    font-size: 16px;
  }
  .container_img{
    width: 100%;
    height: 250px;
  }
  .container_img img{
    width: 100%;
    height: 100%;
  }
  .container_img:hover{
    transform: scale(1.4);
  }
}


  
@media screen and (max-width: 320px){
  .container_titulo{
    font-size: 14px;
  }
  .container_img{
    width: 100%;
    height: 200px;
  }

  .container_img:hover{
    transform: scale(1.4);
  }
}
  
