.container_formulario{
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 400px;
  align-items: center;
  justify-content: center;
  padding: 20px, 20px, 20px, 20px;
}

.container_input{
  padding-bottom: 20px;
  width: 100%;
}

.input{
  width: 100%;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 5px;
}
.input_mensaje{
  width: 100%;
  height: 100px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 5px;
}

.button{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 4px;
  color: #cd4118bf;
  position: relative;
  overflow: hidden;
  z-index: 1;
  -webkit-transition: color 150ms ease-in-out;
  transition: color 150ms ease-in-out;
  border: 1px solid #cd4118bf;
  height: 50px;
  width: 150px;
  text-align: center;
  font-size: 25px;
  padding: 5px;
  text-decoration: none;
  font-family: 'Bebas Neue', cursive;
}

.button:after {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  height: 100%;
  background: #cd4118bf;
  z-index: -1;
  -webkit-transition: width 150ms ease-in-out;
  transition: width 150ms ease-in-out;
  
}
.button:hover {
  color: #fff;
  text-decoration:none;
}
.button:hover:after {
  width: 110%;
  /* text-decoration:none; */
}

.saludo{
  display: flex;
  color: #1d0e11;
  background-color: #e4e6e7;
  border-radius: 10px;
  box-shadow: -8px 12px 2px 0px rgb(205 65 24 / 75%);
  font-family: 'Bebas Neue', cursive;
}
.saludo h1 {
  margin: 0;
  padding: 10px
}

@media screen and (max-width: 1024px) {
  .container_formulario{
    width: calc(100% - 300px);
  }
}

@media screen and (max-width: 550px) {
  .container_formulario{
    width: calc(100% - 100px);
  }
}