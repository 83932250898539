
.container_home{
  display: flex;
  font-family: 'DM Serif Display', serif;
  font-size: 1.5rem;
  color: #e4e6e7;
  /* margin-top: 50px; */
  justify-content: center;
  align-items: center;
  height: 100vh;
}


.container_img{
  border-radius: 9999px;
  height: 400px;
  width: 400px;
}

.container_img img{
  border-radius: 9999px;
}

.container_texto{
  margin-left: 20px;
}
.container_texto h2{
  text-align: center;
}

.minombre{
  font-size: 48px;
  color: #cd4118;
}

.container_texto_change{
  color: black
}

.container_icon{
  
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 0 20px 0;
  
}

.subcontainer_icon{
  cursor: pointer;
  background-color: #e4e6e7;
  border-radius: 10px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  border-color: #cd4118;
  color: #cd4118;
  position: relative;
  overflow: hidden;
  z-index: 1;
  -webkit-transition: color 150ms ease-in-out;
  transition: color 150ms ease-in-out;
  border: 2px solid #cd4118;
  
  text-align: center;
}

.subcontainer_icon a{
  height: 30px;
  color: #cd4118
}

.a:after {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  height: 100%;
  background: #cd4118;
  z-index: -1;
  -webkit-transition: width 150ms ease-in-out;
  transition: width 150ms ease-in-out;
  
}
.a:hover {
  color: #fff;
  text-decoration:none;
}
.a:hover:after {
  width: 110%;
  /* text-decoration:none; */
}

@media screen and (max-width: 1024px){
  .container_texto{
    width: 400px;
    text-align: center;
  }
  .container_texto h1{
    font-size: 40px;
  }
  .minombre{
    font-size: 40px;
  }
}

@media screen and (max-width: 768px){
  .container_home{
    height: 90vh;
  }

  .container_texto{
    width: 400px;
    text-align: center;
  }
  .container_texto h1{
    font-size: 35px;
  }
  .container_texto h2{
    font-size: 30px;
  }
  .minombre{
    font-size: 35px;
  }
  .container_img{
    width: 300px;
    height: 300px;
  }
  
  .container_img img{
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 550px){
  .container_home{
    flex-direction: column;
    height: 90vh;
  }

  .container_texto{
    width: 100%;
    text-align: center;
    margin: 0;
  }
  .container_texto h1{
    font-size: 30px;
  }
  .container_texto h2{
    font-size: 25px;
  }
  .minombre{
    font-size: 30px;
  }
  .container_img{
    width: 200px;
    height: 200px;
  }
  
  .container_img img{
    width: 100%;
    height: 100%;
  }
  .container_icon{
    width: 100%;
  }
}

